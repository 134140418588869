module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wassim Nassour Portfolio","short_name":"Wassim","description":"The application does cool things and makes your life better.","lang":"en","display":"standalone","icon":"src/images/logo.png","start_url":"/","background_color":"#663399","theme_color":"#fff","localize":[{"start_url":"/de/","lang":"de","name":"Die coole Anwendung","short_name":"Coole Anwendung","description":"Die Anwendung macht coole Dinge und macht Ihr Leben besser."}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0d9b4b8b0a099ec58da188f1debfa9f6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-157610078-1"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":850,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    }]
