// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templete-all-posts-all-posts-jsx": () => import("./../../../src/templete/allPosts/allPosts.jsx" /* webpackChunkName: "component---src-templete-all-posts-all-posts-jsx" */),
  "component---src-templete-blog-category-blog-category-jsx": () => import("./../../../src/templete/blogCategory/blogCategory.jsx" /* webpackChunkName: "component---src-templete-blog-category-blog-category-jsx" */),
  "component---src-templete-blog-post-jsx": () => import("./../../../src/templete/blog/post.jsx" /* webpackChunkName: "component---src-templete-blog-post-jsx" */)
}

